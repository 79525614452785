import React from "react";
import "./SoftwareSkill.scss";
import {skillsSection} from "../../portfolio";

export default function SoftwareSkill() {
  return (
    <div>
      <div className="software-skills-main-div">
        <div className="dev-icons">
          {skillsSection.softwareSkills.map((skills, i) => {
            return (
              <div
                key={i}
                className="software-skill-inline"
                name={skills.skillName}
              >
                <div className="icon">
                  <div class="icon-content">
                  {skills.svg?
                    getIcon(skills.svg):                
                    <i className={skills.fontAwesomeClassname}></i>
                  }
                  </div>
                  <div className="skill-name">{skills.skillName}</div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

function getIcon(iconName){
  switch (iconName) {
    case "kotlin":
      return kotlinIcon();
    case "sql":
      return sqlIcon();
    case "flutter":
      return flutterIcon();
    case "cosmos":
      return cosmosIcon();
    case "kubernetes":
      return kubernetesIcon();
    default:
      return null;
  }
}
function kotlinIcon() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="3rem"  viewBox="0 0 50 50">
      <path d="M46 46L6 46 26 26zM45 4L4 45.17 4 25.83 25.83 4zM23 4L4 23 4 4z">
</path>
    </svg>
  );
}


function sqlIcon(){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="3rem" viewBox="0 0 24 24">
      <path d="M7.414,23.964c-3.84-.226-7.414-1.649-7.414-4.178v-.96c1.472,1.047,3.67,1.782,6.275,2.053,.043,.545,.084,.993,.106,1.224,.074,.742,.453,1.401,1.032,1.861Zm1.086-9.964c4.865,0,8.5-1.812,8.5-3.433v-2.167c-1.876,1.596-4.92,2.6-8.5,2.6S1.876,9.996,0,8.4v2.167c0,1.621,3.635,3.433,8.5,3.433Zm-2.353,4.85c-.039-.873-.049-1.699,.013-2.179,.036-.272,.105-.532,.188-.785-2.637-.265-4.862-1.003-6.348-2.06v1.74c0,1.346,2.511,2.821,6.147,3.283Zm2.353-9.85c4.694,0,8.5-2.015,8.5-4.5S13.194,0,8.5,0,0,2.015,0,4.5s3.806,4.5,8.5,4.5Zm14.698,12.404c-.754,0-1.331,0-1.331,0v-5.601c0-.442-.358-.8-.8-.8h0c-.442,0-.8,.358-.8,.8v6.401c0,.442,.358,.8,.8,.8h2.133c.442,0,.8-.358,.8-.8h0c0-.442-.36-.8-.802-.8Zm-11.91-3.055c-.56-.227-1.083-.467-1.364-.595-.098-.045-.428-.173-.433-.615-.002-.131,.103-.441,.515-.577,.427-.138,.831-.046,1.136,.088,.304,.133,.659,.038,.862-.224,.278-.359,.165-.886-.24-1.092-.549-.278-1.312-.479-2.15-.203-.796,.262-1.358,.95-1.469,1.794-.106,.817,.23,1.563,.921,2.047,.003,0,.838,.409,1.752,.779,.214,.086,.904,.409,.816,.958-.065,.391-.491,.794-1.095,.794-.381,0-.752-.106-1.068-.297-.285-.172-.651-.125-.887,.111-.329,.328-.269,.873,.119,1.13,.538,.356,1.174,.551,1.837,.551,1.22,0,2.243-.852,2.434-2.026,.143-.881-.224-2.034-1.683-2.625Zm7.878,3.689c.312,.312,.312,.819,0,1.131-.312,.312-.819,.312-1.131,0l-.433-.433c-.453,.224-.973,.331-1.537,.226-1.209-.224-2.065-1.314-2.065-2.543v-2.917c0-1.365,1.093-2.474,2.452-2.5,1.401-.027,2.548,1.154,2.548,2.556v2.944c0,.398-.101,.769-.267,1.102l.433,.433Zm-1.766-4.535c0-.496-.404-.9-.9-.9s-.9,.404-.9,.9v3c0,.496,.404,.9,.9,.9s.9-.404,.9-.9v-3Z"/>
    </svg>
  );
}

function flutterIcon(){
  return (
  <svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="3rem"  viewBox="0 0 50 50">
    <path d="M 27 3 A 1.0001 1.0001 0 0 0 26.292969 3.2929688 L 5.2929688 24.292969 A 1.0001 1.0001 0 0 0 5.2929688 25.707031 L 11.292969 31.707031 A 1.0001 1.0001 0 0 0 12.707031 31.707031 L 39.707031 4.7070312 A 1.0001 1.0001 0 0 0 39 3 L 27 3 z M 27.414062 5 L 36.585938 5 L 12 29.585938 L 7.4140625 25 L 27.414062 5 z M 28 22 A 1.0001 1.0001 0 0 0 27.292969 22.292969 L 15.292969 34.292969 A 1.0001 1.0001 0 0 0 15.292969 35.707031 L 21.292969 41.707031 L 27.292969 47.707031 A 1.0001 1.0001 0 0 0 28 48 L 40 48 A 1.0001 1.0001 0 0 0 40.707031 46.292969 L 29.414062 35 L 40.707031 23.707031 A 1.0001 1.0001 0 0 0 40 22 L 28 22 z M 28.414062 24 L 37.585938 24 L 22 39.585938 L 17.414062 35 L 28.414062 24 z M 28 36.414062 L 37.585938 46 L 28.414062 46 L 23.414062 41 L 28 36.414062 z">
    </path>
  </svg>
  );
}

function cosmosIcon(){
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width="3rem" viewBox="0 0 18 18">
      <defs>
      <radialGradient id="a" cx="-105.006" cy="-10.409" r="5.954" gradientTransform="matrix(1.036 0 0 1.027 117.739 19.644)" gradientUnits="userSpaceOnUse">
      <stop offset=".183" stop-color="#5ea0ef"/>
      <stop offset="1" stop-color="#0078d4"/>
      </radialGradient>
      <clipPath id="b">
      <path d="M14.969 7.53a6.137 6.137 0 11-7.395-4.543 6.137 6.137 0 017.395 4.543z" />
      </clipPath>
      </defs>
      <path d="M2.954 5.266a.175.175 0 01-.176-.176A2.012 2.012 0 00.769 3.081a.176.176 0 01-.176-.175.176.176 0 01.176-.176A2.012 2.012 0 002.778.72a.175.175 0 01.176-.176.175.175 0 01.176.176 2.012 2.012 0 002.009 2.009.175.175 0 01.176.176.175.175 0 01-.176.176A2.011 2.011 0 003.13 5.09a.177.177 0 01-.176.176zM15.611 17.456a.141.141 0 01-.141-.141 1.609 1.609 0 00-1.607-1.607.141.141 0 01-.141-.14.141.141 0 01.141-.141 1.608 1.608 0 001.607-1.607.141.141 0 01.141-.141.141.141 0 01.141.141 1.608 1.608 0 001.607 1.607.141.141 0 110 .282 1.609 1.609 0 00-1.607 1.607.141.141 0 01-.141.14z"/>
      <path d="M14.969 7.53a6.137 6.137 0 11-7.395-4.543 6.137 6.137 0 017.395 4.543z" />
      <g clip-path="url(#b)">
      <path d="M5.709 13.115a1.638 1.638 0 10.005-3.275 1.307 1.307 0 00.007-.14A1.651 1.651 0 004.06 8.064H2.832a6.251 6.251 0 001.595 5.051zM15.045 7.815c0-.015 0-.03-.007-.044a5.978 5.978 0 00-1.406-2.88 1.825 1.825 0 00-.289-.09 1.806 1.806 0 00-2.3 1.663 2 2 0 00-.2-.013 1.737 1.737 0 00-.581 3.374 1.451 1.451 0 00.541.1h2.03a13.453 13.453 0 002.212-2.11z"/>
      </g>
      <path d="M17.191 3.832c-.629-1.047-2.1-1.455-4.155-1.149a14.606 14.606 0 00-2.082.452 6.456 6.456 0 011.528.767c.241-.053.483-.116.715-.151a7.49 7.49 0 011.103-.089 2.188 2.188 0 011.959.725c.383.638.06 1.729-.886 3a16.723 16.723 0 01-4.749 4.051A16.758 16.758 0 014.8 13.7c-1.564.234-2.682 0-3.065-.636s-.06-1.73.886-2.995c.117-.157.146-.234.279-.392a6.252 6.252 0 01.026-1.63 11.552 11.552 0 00-1.17 1.372C.517 11.076.181 12.566.809 13.613a3.165 3.165 0 002.9 1.249 8.434 8.434 0 001.251-.1 17.855 17.855 0 006.219-2.4A17.808 17.808 0 0016.24 8.03c1.243-1.661 1.579-3.15.951-4.198z" />
    </svg>  
  );
}

function kubernetesIcon(){
  return (
    <svg height="3rem" xmlns="http://www.w3.org/2000/svg"  viewBox="0 0 20 20">
      <g>
      <path d="m2.687 3.21 5.062 4.117c-.477.099-.837.53-.837 1.045 0 .133.024.26.067.377l-5.273-4.287a.81.81 0 0 1 -.124-1.126.776.776 0 0 1 1.105-.126z"/>
      <path d="m7.482 9.322-6.422 1.486a.786.786 0 0 1 -.94-.603.801.801 0 0 1 .592-.958l6.383-1.477a1.076 1.076 0 0 0 -.183.602c0 .414.232.773.57.95z"/>
      <path d="m8.429 9.327-2.905 6.12a.78.78 0 0 1 -1.05.373.807.807 0 0 1 -.365-1.07l2.86-6.028c.143.418.533.718.991.718.169 0 .328-.04.469-.113z"/>
      <path d="m8.97 8.66 2.89 6.09a.807.807 0 0 1 -.365 1.07.78.78 0 0 1 -1.05-.372l-2.89-6.091c.124.053.261.083.405.083.481 0 .886-.33 1.01-.78z"/>
      <path d="m8.462 9.31a1.07 1.07 0 0 0 .546-.938c0-.233-.073-.448-.198-.624l6.478 1.499a.8.8 0 0 1 .592.958.786.786 0 0 1 -.94.603zm.284-8.41v6.766a1.037 1.037 0 0 0 -1.572 0v-6.765c0-.443.352-.801.786-.801s.786.358.786.8z"/>
      <path d="m13.234 3.21a.776.776 0 0 1 1.104.126.81.81 0 0 1 -.123 1.126l-5.275 4.287c.043-.117.067-.244.067-.377 0-.516-.36-.946-.837-1.045l5.063-4.117z"/>
      <path d="m7.96 3.302c-2.75 0-4.978 2.27-4.978 5.07s2.229 5.07 4.978 5.07c2.75 0 4.978-2.27 4.978-5.07s-2.229-5.07-4.978-5.07zm-6.55 5.07c0-3.684 2.933-6.67 6.55-6.67 3.618 0 6.55 2.986 6.55 6.67 0 3.685-2.932 6.671-6.55 6.671-3.617 0-6.55-2.986-6.55-6.67z"/>
      </g>
    </svg>  
);
}